const LS_KEY = {
  accountused: "accountused",
    auth: "auth",
    contractkey: "contractkey",
    user: "user",
    cookie: "cookie",
    currentID: "currentID"
  };
  const set = {
    authToken: (data) => {
      localStorage.setItem(LS_KEY.auth,`Bearer `+ data);
    },
    accountused: (data) => {
      localStorage.setItem(LS_KEY.accountused, data);
    },
    user: (data) => {
      localStorage.setItem(LS_KEY.user, JSON.stringify(data));
    },
    cookie: (data) => {
      localStorage.setItem(LS_KEY.cookie, data);
    },
    currentID: (data) => {
      localStorage.setItem(LS_KEY.currentID, data);
    },
  };
  const fetch = {
 
    auth: () => {
      const data = localStorage.getItem(LS_KEY.auth);
      if (data) {
        try {
          const decoded = data;
          return decoded;
        } catch (err) {}
      }
    },
    accountused: () => {
      const data = localStorage.getItem(LS_KEY.accountused);
      if (data) {
        try {
          const decoded = data;
          return decoded;
        } catch (err) {}
      }
    },
    user: () => {
      const data = localStorage.getItem(LS_KEY.user);
      if (data) {
        try {
          const decoded = JSON.parse(data);
          return decoded;
        } catch (err) {}
      }
    },
    cookie: () => {
      const data = localStorage.getItem(LS_KEY.cookie);
      if (data) {
        try {
          return data;
        } catch (err) {}
      }
    },
    currentID: () => {
      const data = localStorage.getItem(LS_KEY.currentID);
      if (data) {
        try {
          return data;
        } catch (err) {}
      }
    },
  };
  const destory = {
    auth: () => {
      localStorage.removeItem(LS_KEY.auth);
    },
    contractKey: () => {
      localStorage.removeItem(LS_KEY.contractkey);
    },
  };
  export const storage = {
    set,
    fetch,
    destory,
  };
  