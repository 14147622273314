import React, { useEffect } from 'react'
import { CSS } from './CSS'
import { Checkbox, FormLabel, Grid, MenuItem, Select, TextField, Typography } from '@mui/material'
import CustomButton from './CustomButton'
import SelectField from './SelectField'

const AddEditForm = ({ formik, updatepassword, setUpdatepassword, edit = false }) => {
// console.log(formik.values);
// console.log(formik.errors);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container columnGap={2}>
                    <Grid item sx={{ width: "50%", marginTop: "20px" }}>
                        <FormLabel>First Name<span style={{ color: 'red' }}>*</span></FormLabel>
                        <TextField
                            fullWidth
                            value={formik?.values?.firstname}
                            name='firstname'
                            onChange={formik.handleChange}
                            inputProps={{
                                style: {
                                    height: "10px",
                                }
                            }}
                            placeholder='Enter First name'
                            InputProps={{
                                pt: 1,
                            }}
                            sx={CSS.Modal.field} />
                        {formik.errors.firstname && formik.touched.firstname ? (
                            <p style={CSS.Modal.validationStyle}>{formik.errors.firstname}</p>
                        ) : null}
                    </Grid>
                    <Grid item sx={{ width: "48.5%", marginTop: "20px" }}>
                        <FormLabel>Last Name<span style={{ color: 'red' }}>*</span></FormLabel>
                        <TextField
                            fullWidth
                            value={formik?.values?.lastname}
                            name='lastname'
                            onChange={formik.handleChange}
                            placeholder='Enter Last name'
                            inputProps={{
                                style: {
                                    height: "10px",
                                }
                            }}
                            InputProps={{
                                pt: 1,
                            }}
                            sx={CSS.Modal.field} />
                        {formik.errors.lastname && formik.touched.lastname ? (
                            <p style={CSS.Modal.validationStyle}>{formik.errors.lastname}</p>
                        ) : null}
                    </Grid>
                </Grid>
                <Grid container columnGap={2}>
                    <Grid item sx={{ width: "50%", marginTop: "20px" }}>
                        <FormLabel >Email<span style={{ color: 'red' }}>*</span></FormLabel>
                        <TextField
                            fullWidth
                            value={formik?.values?.email}
                            name='email'
                            placeholder='Enter email'
                            onChange={formik.handleChange}
                            inputProps={{
                                style: {
                                    height: "10px",
                                }
                            }}
                            sx={CSS.Modal.field} />
                        {formik.errors.email && formik.touched.email ? (
                            <p style={CSS.Modal.validationStyle}>{formik.errors.email}</p>
                        ) : null}
                    </Grid>
                    <Grid item sx={{ width: "48.5%", marginTop: "20px" }}>
                        <FormLabel >Role<span style={{ color: 'red' }}>*</span></FormLabel>
                        <Select
                            fullWidth
                            variant="outlined"
                            name="role_id"
                            value={formik?.values?.role_id}
                            onChange={formik.handleChange}
                            SelectDisplayProps={{
                                style: {
                                    marginLeft: '10px',
                                    textTransform: 'capitalize',
                                    padding:"10px"
                                },
                            }}
                            placeholder='Select Role'
                            MenuProps={{
                                style: {
                                    zIndex: 99999,
                                },
                                disableScrollLock: true,
                            }}
                            inputProps={{
                                style: {
                                    height: '100px',
                                    textTransform: 'capitalize',
                                },
                            }}
                            color="primary"
                        >
                            {[{ name: "Admin", role_id: 1 }, { name: "User", role_id: 2 }]?.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    sx={{
                                        textTransform: 'capitalize',
                                        '&.css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root': {
                                            zIndex: 99999,
                                        },
                                    }}
                                    value={item.role_id}
                                >
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                        {formik.errors.role_id && formik.touched.role_id ? (
                            <p style={CSS.Modal.validationStyle}>{formik.errors.role_id}</p>
                        ) : null}
                    </Grid>
                </Grid>
                {edit && <Grid display={"flex"}>
                    <Checkbox sx={{ pl: 0 }} onChange={() => setUpdatepassword(!updatepassword)} />
                    <Typography sx={{ mt: 1.2 }}>Do you want to update password?</Typography>
                </Grid>}
                {!edit || updatepassword ?
                    <Grid container columnGap={2}>
                        <Grid item sx={{ width: "50%", marginTop: "20px" }}>
                            <FormLabel >Password<span style={{ color: 'red' }}>*</span></FormLabel>
                            <TextField
                                fullWidth
                                value={formik?.values?.password}
                                name='password'
                                type='password'
                                placeholder='Enter password'
                                onChange={formik.handleChange}
                                inputProps={{
                                    style: {
                                        height: "10px",
                                    }
                                }}
                                InputProps={{
                                    pt: 1,
                                }}
                                sx={CSS.Modal.field} />
                            {formik.errors.password && formik.touched.password ? (
                                <p style={CSS.Modal.validationStyle}>{formik.errors.password}</p>
                            ) : null}
                        </Grid>
                        <Grid item sx={{ width: "48.5%", marginTop: "20px" }}>
                            <FormLabel>Confirm Password <span style={{ color: 'red' }}>*</span></FormLabel>
                            <TextField
                                fullWidth
                                name='confirmPassword'
                                placeholder='Enter confirm password'
                                onChange={formik.handleChange}
                                value={formik?.values?.confirmPassword}
                                type='password'
                                inputProps={{
                                    style: {
                                        height: "10px",
                                    }
                                }}
                                InputProps={{
                                    pt: 1,
                                }}
                                sx={CSS.Modal.field} />
                            {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                                <p style={CSS.Modal.validationStyle}>{formik.errors.confirmPassword}</p>
                            ) : null}
                        </Grid>
                    </Grid> : null
                }
                <Grid>
                    <CustomButton type={"submit"} name={edit ? "Update" : "Add"} color={"black"} />
                </Grid>
            </form>
        </>
    )
}

export default AddEditForm