import { Box, Button, Card, Chip, FormLabel, Grid, IconButton, InputAdornment, Modal, Rating, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useInsertionEffect, useLayoutEffect, useState } from 'react'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { API_URL, Img_URL } from '../../Config/URL';
import { CSS } from '../../Components/CSS';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import PaymentIcon from '@mui/icons-material/Payment';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';
import axios from 'axios';
import { useFormik } from 'formik'
import EditIcon from '@mui/icons-material/Edit';
import Form from '../../Components/Form';
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux';
import { CreateBid, Project_by_id } from '../../redux/Projects/ProjectsAction';
import { storage } from '../../Config/Storage';
import LaunchIcon from '@mui/icons-material/Launch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Toastify from '../../Components/SnackBar/Toastify';
import LoaderComponent from '../../Components/Loader/LoaderComponent';
// Utility function to convert newlines to <br /> tags
const convertNewlinesToBr = (text) => {
    return text?.replace(/\n/g, '<br />');
};

const Schema = {
    bid: yup.object().shape({
        amount: yup.number("Please enter valid number").required('Please enter the amout'),
        days: yup.number("Please enter valid number").required('Please enter the number of days'),
        description: yup
            .string()
            .required('Please enter the proposal').min(100, "Enter atleast 100 characters").max(1500, "Enter maximum 1500 characters")
    })
}

const Project = () => {

    const { bid, details } = useSelector((state) => state?.ProjectData) || {};
    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [first, setfirst] = useState(false)
    const [refresh, setRefresh] = useState(0);
    const [date, setdate] = useState()
    const [loading, setLoading] = useState(true)
    const [rating, setRating] = useState()
    const [upgrades, setUpgrades] = useState(details?.upgrades);
    const [edit, setEdit] = useState(false)
    const minimum = Number(details?.budget?.minimum) || 0;
    const maximum = Number(details?.budget?.maximum) || 0;
    var am = Math.round(minimum + maximum) / 2
    const [defaultValues, setDefaultValues] = useState({
        amount: am,
        days: 7,
        description: ""
    })

    useEffect(() => {
        dispatch(Project_by_id({ project_id: params?.id, account_used: storage.fetch.accountused() }, setLoading))
    }, [])


    const formik = useFormik({
        initialValues: defaultValues,
        validationSchema: Schema.bid,
        onSubmit: (value) => {
            onSubmit(value)
        },
        enableReinitialize: true,
    })

    const onSubmit = (model) => {
        setLoading(true)
        model.amount = Number(model.amount)
        if (!details.bid_id) {
            Object.assign(model, { project_id: JSON.parse(params.id), project_name: details.title, account_used: storage.fetch.accountused() })
            dispatch(CreateBid(model, "", setLoading))
        } else {
            Object.assign(model, { id: storage.fetch.currentID(), project_name: details.title })
            let values = {
                page: 1,
                limit: 5,
                userId: '',
                startDate: '',
                endDate: '',
                biddedFrom: '',
            }
            dispatch(CreateBid(model, values, setLoading))
        }
        setRefresh(refresh + 1); // Change state to trigger re-render

        // setEdit(!edit)
    }
    // console.log(details);


    useEffect(() => {
        //Converting date to mm/dd/yyyy hh:mm:ss format

        const date = new Date(details?.owner?.registration_date * 1000);
        setRating(details?.rating)
        // Define options for formatting
        const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        };
        setdate(date.toLocaleDateString('en-US', options))

        if (details?.bid_id) {
            setDefaultValues({
                amount: details?.amount,
                days: details?.period,
                description: details?.bid_desc
            })
        } else {
            setDefaultValues({
                amount: am,
                days: 7,
                description: ""
            })
        }
        // if (generated?.content && first) {
        //     setDefaultValues({
        //         amount: am,
        //         days: 7,
        //         description: generated?.content
        //     })
        //     setLoading(false)

        // }
    }, [details])
    useEffect(() => {
        // Update state if `item.upgrades` changes
        setUpgrades(details?.upgrades);
    }, [details?.upgrades]);

    useEffect(() => {
        formik.resetForm(
            {
                amount: am,
                days: 7,
                description: ""
            }
        )
    }, [defaultValues])



    const handleEdit = () => {
        setEdit(!edit)
        // dispatch(Getbid({ id: id }))
    };

    //Function to close Modal
    const handleClose = () => {
        formik.resetForm(
            {
                amount: am,
                days: 7,
                description: ""
            }
        )
        setDefaultValues({
            amount: am,
            days: 7,
            description: ""
        })
        setEdit(false)
        setfirst(false)
    };


    //Function to download Attachments
    const handleAttach = async (url, filename, ct) => {
        const fileUrl = API_URL + `projects/downloadfile`;
        try {
            const response = await axios({
                url: fileUrl,
                method: 'POST',
                responseType: 'blob', // Important for handling binary data
                data: {
                    "url": url,
                    "filename": filename,
                    "content_type": ct
                },
                headers: {
                    'Content-Type': 'application/json' // Ensure this matches what your backend expects
                }
            });

            // Create a new Blob object using the response data
            const blob = new Blob([response.data], { type: response.headers['content-type'] });

            // Create a link element
            const link = document.createElement('a');

            // Set the download attribute with the filename
            link.href = URL.createObjectURL(blob);
            link.download = filename;

            // Append the link to the body
            document.body.appendChild(link);

            // Trigger a click event on the link to start the download
            link.click();

            // Clean up by removing the link from the DOM
            document.body.removeChild(link);

            // Revoke the object URL after the download is triggered
            URL.revokeObjectURL(link.href);

        } catch (error) {
        }
    };



    return (
        <Box className="modal" sx={CSS.Modal.modal}>
            <LoaderComponent open={loading} />
            <Box sx={CSS.Modal.box}>
                <Toastify />

                <Typography id="modal-modal-title" sx={{ width: "80%", ml: 6, fontWeight: 700, fontSize: '26px', display: "flex", alignItems: "center" }}>
                    {details?.title}
                    <Link to={Img_URL + "/projects/" + details?.seo_url} target="_blank" ><LaunchIcon /></Link>
                </Typography>
                <Grid container item xs={3} sm={3} md={3} lg={3} xl={3} columnGap={4}>
                    <Grid>
                        <Typography sx={CSS.Modal.head}>
                            Bids
                        </Typography>
                        <Typography sx={CSS.Modal.value}>
                            {details?.bid_stats?.bid_count}
                        </Typography>
                    </Grid>
                    <Grid>
                        <Typography sx={CSS.Modal.head}>
                            Average Bid
                        </Typography>
                        <Typography sx={CSS.Modal.value}>
                            {details?.currency?.sign}{Math.round(details?.bid_stats?.bid_avg)} {details?.currency?.code}
                        </Typography>
                    </Grid>
                </Grid>

            </Box>
            <Grid container item xs={12} sm={12} md={12} lg={12} xl={12} columnGap={4} sx={{ mb: 2 }}>

                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ ml: 6 }}>
                    <Box sx={CSS.Modal.box}>
                        <Typography sx={CSS.Modal.heading}>Project Details</Typography>
                        <Typography component="span">Budget : {details?.currency?.sign}{details?.budget?.minimum} - {details?.currency?.sign}{details?.budget?.maximum} {details?.currency?.code} {details?.type == 'hourly' && `/ hour`}</Typography>
                    </Box>
                    <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }} >
                        <div dangerouslySetInnerHTML={{ __html: convertNewlinesToBr(details?.description) }} />
                    </Typography>
                    <Typography sx={CSS.Modal.heading}>Skills Required</Typography>
                    <Stack direction="row" spacing={1} rowGap={2} sx={{ pt: 2, display: 'flex', flexWrap: "wrap", mb: 2 }}>
                        {details?.jobs?.map((i, ind) =>
                            <Chip label={i.name} key={ind} />
                        )}
                    </Stack>
                    {details?.attachments?.length !== 0 &&
                        <><Typography sx={CSS.Modal.heading}>Attachments</Typography>
                            <Grid item display={"inline-grid"} xs={12} sx={{ minWidth: '100%' }}>
                                {details?.attachments?.map((item) =>
                                    <Box sx={{ display: "flex", m: 0.5, }}>
                                        <AttachFileIcon fontSize='18px' sx={{ mt: 1.2, mr: 2 }} />
                                        <Button onClick={() => handleAttach(`https://${item.url}`, item.filename, item.content_type)} target='_blank'>{item.filename}</Button>
                                    </Box>
                                )}
                            </Grid></>}
                    {upgrades?.sealed && <Chip label={"Sealed"} key="sealed" sx={{ bgcolor: '#589af0', color: "white", mr: 1 }} />}
                    {upgrades?.NDA && <Chip label={"NDA"} key="nda" sx={{ bgcolor: '#115cc0', color: "white", mr: 1 }} />}
                    {upgrades?.recruiter && <Chip label={"Recruiter"} key="recruiter" sx={{ bgcolor: '#7f21f7', color: "white", mr: 1 }} />}
                    {upgrades?.ip_contract && <Chip label={"IP Contract"} key="ip_contract" sx={{ bgcolor: '#860146', color: "white", mr: 1 }} />}

                    <Box style={{ fontSize: "12px", width: "100%" }}>Project ID:{details?.id}</Box>

                </Grid>

                <Grid item xs={12} md={2} sx={{
                    ml: { xs: 0, md: 9 }, mt: 2, "&.MuiGrid-root": {
                        flexBasis: { md: "22.666667%" }
                    }
                }}>
                    <Card sx={{ p: 2, width: "100%" }}>
                        <Typography sx={{ fontSize: "20px", fontWeight: 600, mb: 1 }}>About the Client</Typography>
                        <Grid display={"flex"}>
                            <LocationOnIcon />
                            <Box sx={{ ml: 2 }}>{details?.owner?.location?.country?.name}
                            </Box>
                        </Grid>
                        <Grid display={"flex"} mt={1}>
                            <img src={Img_URL + details?.owner?.location?.country?.flag_url} width={"25px"} height={"20px"} />
                            <Box sx={{ ml: 2 }}>{details?.owner?.location?.city}</Box>
                        </Grid>
                        <Grid display={"flex"} mt={1}>
                            <PersonIcon />
                            <Box sx={{ display: 'flex', ml: 2 }}>
                                <Rating name="read-only" value={rating !== undefined ? rating?.toFixed(1) : 0} precision={0.1} readOnly />
                                <Typography>{details?.rating !== undefined ? rating?.toFixed(1) : 0}</Typography>
                            </Box>
                        </Grid>
                        <Grid display={"flex"} mt={1}>
                            <AccessTimeFilledIcon />
                            <Box sx={{ display: 'flex', ml: 2 }}>
                                <Typography variant="body1"> {/* Use Typography for consistency */}
                                    Member Since {date}
                                </Typography>
                            </Box>
                        </Grid>
                        <Typography sx={{ fontSize: "20px", fontWeight: 600, mb: 1, mt: 2 }}>Client Verification</Typography>
                        <Grid display={"flex"} >
                            <BrandingWatermarkIcon />
                            <Box sx={{ display: 'flex', ml: 2 }}>
                                {details?.owner?.status?.identity_verified ? "Identity verified" : "Identity not verified"}
                            </Box>
                        </Grid>
                        <Grid display={"flex"} mt={1}>
                            <CreditScoreIcon />
                            <Box sx={{ ml: 2 }}>
                                {details?.owner?.status?.payment_verified ? "Payment Verified" : "Payment not verified"}
                            </Box>
                        </Grid>
                        <Grid display={"flex"} mt={1}>
                            <EmailIcon />
                            <Box sx={{ ml: 2 }}>
                                {details?.owner?.status?.email_verified ? "Email Verified" : "Email not verified"}
                            </Box>
                        </Grid>
                        <Grid display={"flex"} mt={1}>
                            <PaymentIcon />
                            <Box sx={{ ml: 2 }}>
                                {details?.owner?.status?.deposit_made ? "Deposit made" : "Deposit not made"}
                            </Box>
                        </Grid>
                        <Grid display={"flex"} mt={1}>
                            <LocalPhoneIcon />
                            <Box sx={{ ml: 2 }}>
                                {details?.owner?.status?.phone_verified ? "Phone Verified" : "Phone not verified"}
                            </Box>
                        </Grid>
                    </Card>
                </Grid>

            </Grid>
            {/* {!details?.bid_id ?
                :
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} columnGap={3}>
                    <Typography>You have already placed a bid on this project.</Typography>
                    <EditIcon sx={{ cursor: "pointer" }} onClick={handleEdit} />
                </Grid>
            } */}
            {details?.bid_id ? <Form add={false} data={details} defaultValues={defaultValues} formik={formik} setLoading={setLoading} loading={loading} setfirst={setfirst} handleClose={handleClose} />
                :
                <Form add={true} data={details} defaultValues={defaultValues} formik={formik} setfirst={setfirst} loading={loading} setLoading={setLoading} handleClose={handleClose} />
            }

        </Box>
    )
}

export default Project