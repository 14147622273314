import React, { useEffect, useState } from 'react';
import Header from '../../Components/Header';
import { Box, IconButton, Modal, Pagination, Typography, Select, MenuItem, TextField, Button } from '@mui/material';
import { CSS } from '../../Components/CSS';
import { allbids, bids, Project_by_id } from '../../redux/Projects/ProjectsAction';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import ModalBox from '../../Components/Modal';
import { getLoading, users } from '../../redux/User/UserAction';
import Toastify from '../../Components/SnackBar/Toastify';
import LoaderComponent from '../../Components/Loader/LoaderComponent';
import RefreshIcon from '@mui/icons-material/Refresh';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { CSVLink } from 'react-csv'
import Heading from '../../Components/Heading';
import { storage } from '../../Config/Storage';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const Mybids = () => {
    const { bidsData, details, allbidsData } = useSelector((state) => state?.ProjectData) || {};
    const detail = useSelector((state) => state) || {};
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rows, setRows] = useState();
    const [Id, setID] = useState();
    const [open, setOpen] = useState(false);
    const [pages, setPages] = useState();
    const [loading, setLoading] = useState(true);

    const [values, setValues] = useState({
        page: 1,
        limit: 5,
        userId: '',
        startDate: '',
        endDate: '',
        biddedFrom: '',
    });
    const headers = [
        { label: 'Project id', key: 'project_id' },
        { label: 'Project name', key: 'project' },
        { label: 'Amount', key: 'amount' },
        { label: 'User', key: 'user' },
        { label: 'Account Used', key: 'bidded_from' },
        { label: 'Bid Date', key: 'created_on' },
        { label: 'Update Date', key: 'updated_on' },
        { label: 'Bid id', key: 'bid_id' },
    ]

    const user = storage.fetch.user()

    useEffect(() => {
        dispatch(bids(values, setLoading));
        // if (values.page == 1)
        //     dispatch(users())
    }, [values]);

    useEffect(() => {
        if (bidsData) {
            setRows(bidsData?.data);
            setPages(bidsData?.totalPages);

            // let data = {
            //     page: 1,
            //     limit: bidsData?.totalBids,
            //     userId: values.userId,
            //     startDate: values.startDate,
            //     endDate: values.endDate,
            //     biddedFrom: values.biddedFrom,
            // }
            // if (!allbidsData)
            // dispatch(allbids(data))
        }
    }, [bidsData]);
    useEffect(() => {
    }, [detail, allbidsData])


    const handleEdit = (id, id1, from) => {
        window.open(`/project/${id}`, "_blank");
        storage.set.currentID(id1)
    };

    ///---Pagination Function---///
    const handlePageChange = (event, value) => {
        setLoading(true)
        setValues((prev) => ({ ...prev, page: value }));
    };

    const handleFilterChange = (event) => {
        const { name, value } = event.target;
        setValues((prev) => ({ ...prev, [name]: value }));
    };
    const handleRefresh = () => {
        setValues({
            page: 1,
            limit: 5,
            userId: '',
            startDate: '',
            endDate: '',
            biddedFrom: '',
        });
    };

    return (
        <>
            {user.role_id !== 1 && <Header />}
            <Box sx={user.role_id !== 1 ? CSS.Dashboard.box : ""}>
                {user.role_id !== 1 && <Heading headingtext={"All Bids"} />}


                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                    <Select
                        name="userId"
                        value={values.userId}
                        onChange={handleFilterChange}
                        displayEmpty
                        sx={{ width: 200 }}
                    >
                        <MenuItem value="">All Users</MenuItem>
                        {detail?.UserData?.userDetails?.data?.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                                {item.firstname} {item.lastname}
                            </MenuItem>
                        ))}
                    </Select>

                    <Select
                        name="biddedFrom"
                        value={values.biddedFrom}
                        onChange={handleFilterChange}
                        displayEmpty
                        sx={{ width: 200 }}
                    >
                        <MenuItem value="">All Accounts</MenuItem>
                        <MenuItem value="OOCPL">OOCPL</MenuItem>
                        <MenuItem value="Bhawan">Bhawan</MenuItem>
                        {/* Add more options if needed */}
                    </Select>

                    <TextField
                        name="startDate"
                        label="Start Date"
                        type="date"
                        value={values.startDate}
                        onChange={handleFilterChange}
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: 200 }}
                    />
                    <TextField
                        name="endDate"
                        label="End Date"
                        type="date"
                        value={values.endDate}
                        onChange={handleFilterChange}
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: 200 }}
                    />

                    <IconButton onClick={handleRefresh}> <RefreshIcon /></IconButton>
                    <CSVLink
                        style={CSS.Bids.Export}
                        data={bidsData?.data || []}
                        headers={headers}
                        filename={'Bids.csv'}
                        className="btn btn-primary"
                        target="_blank"
                    >
                        Export
                    </CSVLink>

                </Box>
            </Box>

            <ModalBox open={open} data={details} setLoading={setLoading} loading={loading} setopen={setOpen} id={Id} values={values} />
            <LoaderComponent open={loading} />
            <TableContainer component={Paper}>
                <Toastify />
                <Table sx={{ minWidth: 700, maxWidth: user.role_id === 1 ? '100%' : '96.8%', m: user.role_id === 1 ? 0 : 3 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Project</StyledTableCell>
                            <StyledTableCell align="right">User</StyledTableCell>
                            <StyledTableCell align="right">Account used</StyledTableCell>
                            <StyledTableCell align="right">Date</StyledTableCell>
                            <StyledTableCell align="right">Last Updated</StyledTableCell>
                            <StyledTableCell align="right">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.length === 0 && (
                            <StyledTableRow>
                                <StyledTableCell colSpan={6} align="center">
                                    No Data Found
                                </StyledTableCell>
                            </StyledTableRow>
                        )}
                        {rows?.map((row) => (
                            <StyledTableRow key={row?.id}>
                                <StyledTableCell component="th" scope="row" sx={{ color: row?.project_name === "Unknown" ? "red" : "" }}>
                                    {row?.project === "Unknown" ? "Deleted" : row.project_name}
                                </StyledTableCell>
                                <StyledTableCell align="right" sx={{ color: row?.user === "Unknown" ? "red" : "" }}>{row?.user === "Unknown" ? "Deleted" : row?.user}</StyledTableCell>
                                <StyledTableCell align="right">{row?.bidded_from}</StyledTableCell>
                                <StyledTableCell align="right">{row?.formatted_date}</StyledTableCell>
                                <StyledTableCell align="right">{row?.formatted_updated_date === "Invalid Date" ? "NA" : row?.formatted_updated_date}</StyledTableCell>
                                <StyledTableCell align="right">
                                    {row?.user !== "Unknown" && <IconButton onClick={() => handleEdit(row?.project_id, row?.id, row?.bidded_from)}>
                                        <EditIcon />
                                    </IconButton>}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>

            </TableContainer>
            {pages > 1 && (
                <Box sx={{ width: '98%', display: 'flex', justifyContent: 'flex-start', m: 2, ml: 0 }}>
                    <Pagination
                        color="primary"
                        count={pages}
                        page={values?.page}
                        boundaryCount={1}
                        onChange={handlePageChange}
                        siblingCount={0}
                    />
                </Box>
            )}
        </>
    );
};

export default Mybids;
