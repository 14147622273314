import SignIn from "../Pages/SignIn";
import Dashboard from "../Pages/Users/Dashboard";
import ProtectedRoute from "./protectedRoutes";
import OpenRoutes from "./OpenRoutes";
import Mybids from "../Pages/Users/Mybids";
// import Register from "../Pages/Register";
import Settings from "../Pages/Users/Settings";
import AdminDashboard from "../Pages/Admin/AdminDashboard";
import Users from "../Pages/Admin/Users/Users";
import AddUser from "../Pages/Admin/Users/AddUser";
import EditUser from "../Pages/Admin/Users/EditUser";
import Project from "../Pages/Users/Project";

// Define all the routes
export const router = [
  {
    path: "/dashboard",
    element: Dashboard,
    routeType: ProtectedRoute,
  },
  {
    path: "/project/:id",
    element: Project,
    routeType: ProtectedRoute,
  },
  {
    path: "/",
    element: SignIn,
    routeType: OpenRoutes,
  },
  {
    path: "/settings",
    element: Settings,
    routeType: ProtectedRoute,
  },
  {
    path: "/allbids",
    element: Mybids,
    routeType: ProtectedRoute,
  },
  {
    path: "/admindashboard",
    element: AdminDashboard,
    routeType: ProtectedRoute, // Uncomment if needed
  },
  {
    path: "/users/edit/:id",
    element: EditUser,
    routeType: ProtectedRoute, // Uncomment if needed
  },
  {
    path: "/users",
    element: Users,
    routeType: ProtectedRoute, // Uncomment if needed
  },
  {
    path: "/users/add",
    element: AddUser,
    routeType: ProtectedRoute, // Uncomment if needed
  },
  
];

export default router;
