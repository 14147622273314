export const routesData = [
  
    {
        path: "/dashboard",
        link: "Dashboard"
    },
    {
        path: "/allbids",
        link: "All Bids"
    },
    {
        path: "/settings",
        link: "Settings"
    }
]
export const Sidebar = [
  
    {
        path: "/admindashboard",
        link: "Dashboard",
    },
    {
        path: "/users",
        link: "Users"
    },
    {
        path: "/allbids",
        link: "All Bids"
    },
    {
        link: "Logout"
    }
]

export const Header = [
  
    {
        path: "/admindashboard",
        link: "Dashboard",
    },
    {
        path: "/users",
        link: "Users"
    },
    {
        path: "/allbids",
        link: "All Bids"
    },
    {
        path: "/users/add",
        link: "Add User"
    },
    {
        path: "/users/edit",
        link: "Edit User"
    },
    
]

export const oauthAccessToken = 'LLCCGY8VzM51xUEbG4sAC2ipWBMHWy'
export const oauthAccessToken1 = 'E514crTigDmeAcFkXOYpoU11eKXcav'