import { Api } from "../../Config/Requests";
export const getprojects = async (data) => {
  // return Api.DirectGetRequest('https://www.freelancer.com/api/projects/0.1/projects/active/', data);
  return Api.GetRequest('projects/projects/', data);
};
export const getprojectsid = async (data) => {
  // return Api.GetRequest("projects/projects_by_id", data);
  return Api.DirectGetRequest(data);
};
export const postbid = async (data) => {
  return Api.PostRequest("projects/bid", data);
};
export const updatebid = async (data) => {
  return Api.PostRequest("projects/updatebid", data);
};
export const getbid = async (data) => {
  return Api.GetRequest("projects/bidbyid", data);
};
export const getbids = async (data) => {
  return Api.GetRequest("projects/bids", data);
};
export const cookie = async (data) => {
  return Api.PostRequest("projects/cookie", data);
};
export const requestaibid = async (data) => {
  return Api.PostRequest("projects/generate-bid", data);
};
export const requestallbids = async (data) => {
  return Api.GetRequest("projects/bids", data);
};
export const requestcookie = async (data) => {
  return Api.GetRequest("projects/getcookie", data);
};
const projectsApiRequests = {
  getprojects,
  getprojectsid,
  postbid,
  updatebid,
  getbid,
  getbids,
  cookie,
  requestcookie,
  requestaibid,
  requestallbids
};
export default projectsApiRequests;
