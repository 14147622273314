import { put, takeEvery } from "redux-saga/effects";
import { GET_AI_BID, GET_ALL_BIDS, GET_BID, GET_BID_CREATE, GET_BID_CREATE_UPDATED, GET_BIDS, GET_COOKIE, GET_PROJECTS, GET_PROJECTS_ID, GET_UPDATE_COOKIE, GET_USERS, SET_AI_BID, SET_ALL_BIDS, SET_BID, SET_BID_CREATE, SET_BID_CREATE_UPDATED, SET_BIDS, SET_COOKIE, SET_PROJECTS, SET_PROJECTS_ID, SET_UPDATE_COOKIE } from "../ConstAction";
import projectsApiRequests from "../../services/Projects";
import { toast } from "react-toastify";
import { storage } from "../../Config/Storage";
function* getprojects(data) {
  try {
    let response = yield projectsApiRequests.getprojects(data.data);
    // console.log(response);
    if (!response.data.status) {
      toast.error(response.data.error || response.data.message)
    }
    data.setLoading(false)
    yield put({
      type: SET_PROJECTS,
      response,
    });
  } catch (err) { }
}
function* getprojectsid(data) {
  try {
    let response = yield projectsApiRequests.getprojectsid(data.data);
    data?.setLoading(false)

    if (response) {
      if (response.status == 404 || response.status == 400) {
        toast.error(response.message)
        data?.setopen(false)
      } else {
        yield put({
          type: SET_PROJECTS_ID,
          response,
        });
        toast.success("Project details found!!")
      }
    } else {
      data?.setopen(false)
      toast.error(response.data.message)

    }
  } catch (err) { }
}
function* getbidposted(data) {
  try {
    let response
    if (data.data.id) {
      response = yield projectsApiRequests.updatebid(data.data);
    } else
      response = yield projectsApiRequests.postbid(data.data);
      // console.log(data);
      
      if(data)
      data?.setLoading(false)

    yield put({
      type: SET_BID_CREATE_UPDATED,
      response,
    });
    // console.log(response.data)
    setTimeout(() => {
      if (response?.data?.status) {
        if (data.data.id) {
          toast.success("Bid updated Successfully")
        } else {
          toast.success("Bid created Successfully")
        }
      } else {
        // toast.error(response?.data?.message|| response.message)
      }
    }, 2000);

  } catch (err) {
    // console.log(err);
    // if(data)
    // data?.setLoading(false)
    toast.error(err?.data?.message || err.message)

  }
}
function* getbid(data) {
  try {
    let response
    response = yield projectsApiRequests.getbid(data.data);
    yield put({
      type: SET_BID,
      response,
    });
  } catch (err) { }
}
function* getbids(data) {
  try {
    let response
    response = yield projectsApiRequests.getbids(data.data);
    yield put({
      type: GET_USERS,
      data: { page: 1 }
    });
    if (response.data.status) {
      yield put({
        type: SET_BIDS,
        response,
      });
      if (data.setLoading) {
        data?.setLoading(false)
      }
    }
  } catch (err) {
    if (data.setLoading) {
      data?.setLoading(false)
    }
    toast.error("Failed to fetch bids")
  }
}

function* getCookie(data) {
  try {
    let response = yield projectsApiRequests.requestcookie(data.data);
    if (response) {
      yield put({
        type: SET_COOKIE,
        response: response
      });
    }
  } catch (err) {
    toast.error("Failed to fetch cookie")

  }
}

function* updateCookie(data) {
  try {
    let response = yield projectsApiRequests.cookie(data.data);
    if (response) {
      // storage.fetch.
      yield put({
        type: SET_UPDATE_COOKIE,
        response: response
      });
    }
  } catch (err) {
    toast.error("Failed to update cookie")

  }
}
function* getaibid(data) {
  try {
    let response = yield projectsApiRequests.requestaibid(data.data);
    if (response.status) {
      yield put({
        type: SET_AI_BID,
        response: response
      });
    } else {
      toast.error(response.data.message)
    }
  } catch (err) {
    toast.error("Failed to fetch bid")

  }
}
function* getallbids(data) {
  try {
    let response = yield projectsApiRequests.requestallbids(data.data);
    if (response.status) {
      yield put({
        type: SET_ALL_BIDS,
        response: response
      });
    } else {
      toast.error(response.data.message)
    }
  } catch (err) {

  }
}

function* projectSaga() {
  yield takeEvery(GET_PROJECTS, getprojects);
  yield takeEvery(GET_PROJECTS_ID, getprojectsid);
  yield takeEvery(GET_BID_CREATE_UPDATED, getbidposted);
  yield takeEvery(GET_BID, getbid);
  yield takeEvery(GET_BIDS, getbids);
  yield takeEvery(GET_COOKIE, getCookie);
  yield takeEvery(GET_UPDATE_COOKIE, updateCookie);
  yield takeEvery(GET_AI_BID, getaibid);
  yield takeEvery(GET_ALL_BIDS, getallbids);

}
export default projectSaga;
