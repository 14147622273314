import { GET_AI_BID, GET_ALL_BIDS, GET_BID, GET_BID_CREATE_UPDATED, GET_BIDS, GET_COOKIE, GET_PROJECTS,GET_PROJECTS_ID, GET_UPDATE_COOKIE } from "../ConstAction";

export const Allprojects = (data,setNext,setLoading) => {
  return {
    type: GET_PROJECTS,
    data: data,
    setNext: setNext,
    setLoading : setLoading 
  };
};
  export const Project_by_id = (data , setLoading ) => {
    return {
      type: GET_PROJECTS_ID,
      data: data,
      setLoading : setLoading 
    };
};
export const bids = (data,setLoading) => {
  return {
    type: GET_BIDS,
    data: data,
    setLoading: setLoading,
  };
};
export const allbids = (data) => {
  return {
    type: GET_ALL_BIDS,
    data: data,
  };
};
export const CreateBid = (data,values,setLoading) => {
  return {
    type: GET_BID_CREATE_UPDATED,
    data: data,
    values: values,
    setLoading:setLoading
  };
};
export const Getbid = (data) => {
  return {
    type: GET_BID,
    data: data,
  };
};

export const getcookie = (data) => {
  return {
    type: GET_COOKIE,
    data: data,
  };
};

export const updateCookie = (data) => {
  return {
    type: GET_UPDATE_COOKIE,
    data: data,
  };
};
export const generatewithAI = (data) => {
  return {
    type: GET_AI_BID,
    data: data,
  };
};