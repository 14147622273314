import { SET_AI_BID, SET_ALL_BIDS, SET_BID, SET_BIDS, SET_COOKIE, SET_DASHBOARD, SET_LOADING, SET_LOGIN, SET_PROJECTS, SET_PROJECTS_ID } from "../ConstAction";
const initialState = {};
export const ProjectData = (state = initialState, action) => {
  switch (action.type) {

    case SET_PROJECTS:
      return {
        ...state,
        result: action.response.data.result,
        loading: false,
      };
    case SET_PROJECTS_ID:
      return {
        ...state,
        details: action.response.data,
        loading: false,
      };
    case SET_BID:
      return {
        ...state,
        bid: action.response.data.result,
        loading: false,
      };
    case SET_BIDS:
      return {
        ...state,
        bidsData: action.response.data,
        loading: false,
      };
    case SET_ALL_BIDS:
      return {
        ...state,
        allbidsData: action.response.data,
        loading: false,
      };
    case SET_AI_BID:
      return {
        ...state,
        generated: action.response.data,
        loading: false,
      };
    case SET_COOKIE:
      return {
        ...state,
        cookie: action.response.data,
        loading: false,
      };
    case SET_LOADING:
      return {
        loading: action.data,
      };
    default:
      return { ...state };
  }
};
